import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Configuration from "./component";
import {
    getProfileDetails,
    setGAId,
    checkAuthToken,
    getVariantsFromB2COMS,
    mapClientId
} from "./actions";
import { setExperiment } from "../../components/shared/ab-experiment/actions";
import { getCouponForUser, getOptedCouponForUser } from "../../components/mobile/car-list/actions";
import { updateStatsigConfig, updateStatsigTimeStamp } from "../shared/ab-experiment/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        currentPageType,
        userId,
        secureToken,
        gaId,
        buyerABTestSSR,
        isFetched: isLoginFetched,
        mobile,
        isUserSessionValid,
        statsigId,
        gaIdError
    },
    cities: {
        selectedCity,
        isCityUnavailable
    },
    config: { cityList: cities },
    location: {
        pincode
    },
    experiments: {
        statsigConfig
    }

}) => ({
    userId,
    secureToken,
    isLoggedIn,
    cities,
    selectedCity,
    currentPageType,
    pincode,
    // applicableCityForSellOnline,
    gaId,
    isCityUnavailable,
    buyerABTestSSR,
    isLoginFetched,
    mobile,
    isUserSessionValid,
    statsigConfig,
    statsigId,
    gaIdError
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchProfileConnect: getProfileDetails,
    setGAIdConnect: setGAId,
    setExperimentConnect: setExperiment,
    checkAuthTokenConnect: checkAuthToken,
    getVariantsFromB2COMSConnect: getVariantsFromB2COMS,
    getCouponForUserConnect: getCouponForUser,
    getOptedCouponForUserConnect: getOptedCouponForUser,
    mapClientIdConnect: mapClientId,
    updateStatsigConfigConnect: updateStatsigConfig,
    updateStatsigTimeStampConnect: updateStatsigTimeStamp
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
